import Vue from 'vue'
import VueWait from 'vue-wait'
import debounce from 'v-debounce'
import { localize } from 'vee-validate'
import VueConfig from 'vue-configuration'
import { Plugin as FragmentPlugin } from 'vue-fragment'
import dictionary from './../translations/validation-messages'
Vue.use(FragmentPlugin)
import config from './../config'
import './../common/validator'
import { EventBus } from './../services/EventBus'

// global directives
Vue.directive('debounce', debounce)

Vue.use(EventBus)
Vue.use(VueWait)
Vue.use(VueConfig, { config })
//Vue.use(ValidationProvider, { fieldsBagName: 'formFields', events: 'blur|input' })
localize(dictionary)
localize('en')
