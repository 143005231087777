<template>
    <b-modal id="download-apps-modal" ref="download-apps-modal" title="تحميل التطبيق" hide-footer hide-header centered>
        <div class="my-2">
            <button @click="hideModal" type="button" aria-label="Close" class="close close-modal-btn"><span
                    class="hi-close"></span></button>
        </div>
        <div class="col-md-6  d-flex align-items-center p-0 justify-content-end ">
            <h6 class="ml-3">حمل تطبيق <span class="brand-color">هكتار</span> الآن!</h6>
            <a class="mx-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.hectar.hectarapp">
                <img src="@/assets/images/goolge.svg" alt="Google App Icon" loading="lazy">
            </a>
            <a target="_blank" href="https://apps.apple.com/eg/app/hectar-%D9%87%D9%83%D8%AA%D8%A7%D8%B1/id1560571291">
                <img src="@/assets/images/apple.svg" alt="Apple App Icon" loading="lazy">
            </a>
        </div>
    </b-modal>
</template>
<script>
    // Config File
    import config from '@/config';
    export default {
        data() {
            return {
                googlePlayApp: require('../../assets/images/google-play-badge.svg'),
                iosApp: require('../../assets/images/app-store-badge.svg'),
            };
        },
        computed: {
            iosAppUrl() {
                return config.iosAppUrl;
            },
            androidAppUrl() {
                return config.androidAppUrl;
            },
        },
        methods: {
            hideModal() {
                this.$refs['download-apps-modal'].hide();
            },
        }
    };
</script>
<style lang="scss" scoped>
    #download-apps-modal {
        text-align: center;
        .modal-body {
            padding: 35px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            h4 {
                max-width: 260px;
                margin: 0 auto 5px;
                line-height: 1.4;
            }
        }
        .download-app {
            padding: 0;
        }
    }
</style>