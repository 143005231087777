export default {
  app: {
    title: 'App Name'
  },
  keywords: {
    login: 'Login',
    signUp: 'Sign up',
    company_name: 'Company Name',
    industry: 'Industry',
    business_address: 'Business Address',
    country: 'Country',
    state: 'State',
    city: 'City',
    zip_code: 'Zip code',
    business_email: 'Business email',
    first_name: 'First name',
    last_name: 'Last name',
    phone_number: 'Phone number',
    person_email: 'Contact person email',
    username: 'Login Name',
    password: 'Password',
    confirm_password: 'Confirm password',
    signIn: 'Sign In',
    all: 'All',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    start_date: 'Start Date',
    end_date: 'End Date',
    mobile: 'Mobile',
    desktop: 'Desktop',
    back: 'Back',
    next: 'Next',
    age: 'Age',
    language: 'Language',
    save: 'Save',
    avatar: 'Avatar',
    reset_filters: 'Reset all filters',
    publish: 'Publish',
    delete: 'Delete',
    cancel: 'Cancel',
    no: 'No',
    yes: 'Yes',
    type_something: 'Type something',
    format: 'Format',
    pixels: 'pixels',
    placement: 'Placement',
    preview: 'Preview',
    carousel: 'Carousel',
    single_image: 'Single image',
    single_video: 'Single video',
    slide_show: 'Slide show',
    feed: 'Feed',
    right_column: 'Right column',
    left_column: 'Left Column',
    instant_article: 'Instant articles',
    in_stream_video: 'In-Stream Videos',
    stories: 'Stories',
    shop_now: 'Shop now',
    subscribe: 'Subscribe',
    watch_now: 'Watch now',
    download: 'Download',
    learn_more: 'Learn more',
    call_now: 'Call now',
    apply_now: 'Apply now',
    contact_us: 'Contact us',
    id: 'ID'
  },
  route: {
    // [routerName]: translation
    home: 'Home',
    reporting: 'Reporting',
    past_ads: 'Past ads',
    current_ads: 'Current ads',
    current_requests: 'Current Requests',
    pending_requests: 'Pending requests',
    approved_requests: 'Approved requests',
    not_approved_requests: 'Not approved requests',
    rejected_requests: 'Rejected requests',
    create_campaign: 'Create campaign',
    messaging: 'Messaging',
    upload_image: 'Upload image',
    login: 'Login',
    registration: 'Registration'
  },
  login: {
    title: 'Sign in to continue',
    login_placeholder: 'Enter your Login name',
    password_placeholder: 'Enter your password',
    have_not_account: "Don't have an account?"
  },
  errors: {
    err_required: 'This field is required',
    err_unauthorized: 'Please sign in',
    err_internal: 'Internal server error!',
    err_invalid_credentials: 'Invalid credentials',
    err_complete_captcha: 'Please complete captcha',
    err_user_username_cannot_be_null: 'Login name is required',
    err_user_password_cannot_be_null: 'Password is required',
    err_user_personemail_cannot_be_null: 'Person email is required',
    err_user_companyemail_cannot_be_null: 'Business email is required',
    err_phone_not_valid: 'Phone number is not valid',
    err_validation_isemail_on_personemail_failed: 'Person email is not valid',
    err_validation_isemail_on_companyemail_failed:
      'Business email is not valid',
    err_username_must_be_unique: 'Login name must be unique',
    err_personemail_must_be_unique: 'Person email must be unique',
    err_companyemail_must_be_unique: 'Business email must be unique',
    err_phone_must_be_unique: 'Phone number must be unique',
    err_invalid_file_type:
      'Uploaded file is not a valid image. Only JPG and PNG files are allowed.'
  }
}
