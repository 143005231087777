import axios from 'axios'
import config from '../config'
import { authHeader } from '../store/helpers'

export const userService = {
  getConfigrations,
  login,
  checkPhoneNumber,
  checkPhoneNumberForResetPassword,
  confirmPhoneNumber,
  confirmForgetCode,
  resetPassword,
  register,
  getFavorites,
  addToFavorite,
  deleteFromFavorite,
  getAllUsers,
  updateProfile,
  resendCode,
  getUserTypes,
  getUserByAccountId,
  createUser,
  getAgentDetails,
  getAddPropertiesQuota,
  updateProfileData,
  contactSales,
  getAboutUs,
  requestCall
}

function getConfigrations() {
  const url = `${config.apiUrl}/config/info`
  return axios.get(url).then((configs) => {
    localStorage.setItem('configrations', JSON.stringify(configs.data))
    return configs
  })
}

function getUserTypes() {
  const url = `${config.usersUrl}/userTypes/getAll`
  return axios.get(url)
}

function requestCall(body) {
  const url = `${config.usersUrl}/call-request`
  return axios.post(url, body)
}

function login(phoneNumber, password) {
  const data = { phoneNumber, password }
  const url = config.accountsUrl + '/loginUser'
  return axios.post(url, data)
}

// Regsiter new user
function checkPhoneNumber(phoneNumber) {
  const url = config.accountsUrl + '/checkUser'
  return axios.post(url, phoneNumber)
}
function confirmPhoneNumber(data) {
  const url = config.accountsUrl + '/confirmRegister'
  return axios.post(url, data)
}
function register(user) {
  const url = config.accountsUrl + '/addUser'
  return axios.post(url, user)
}

function createUser(user) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.usersUrl + '/users/add'
  return axios.post(url, user, requestOptions)
}

function getUserByAccountId(accountId, token) {
  const requestOptions = {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  const url = `${config.usersUrl}/users/getByAccountId/${accountId}`
  return axios.get(url, requestOptions).then((envs) => {
    return envs
  })
}

// Forget/Reset Password
function checkPhoneNumberForResetPassword(phoneNumber) {
  const url = config.accountsUrl + '/forgetPassword'
  return axios.post(url, phoneNumber)
}
function confirmForgetCode(data) {
  const url = config.accountsUrl + '/confirmForgetCode'
  return axios.post(url, data)
}
function resetPassword(data) {
  const url = config.accountsUrl + '/resetPassword'
  const res = axios.post(url, data)
  return res
}

// get agent details

async function getAgentDetails(id) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.usersUrl}/users/get-realestate-agent-details/${id}`
  return await axios.get(url, requestOptions)
}

// Favorites --------------------------------------------------
async function getFavorites(pageSize = 50, pageNumber = 1) {
  let user = JSON.parse(localStorage.getItem('tokenData'))
  if (user && user.token) {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }
    const url = `${config.realEstatesUrl}/favorites/getUserFav/${user._id}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    return await axios.get(url, requestOptions).then((favs) => {
      return favs
    })
  }
}

// Add Property to Favorite
function addToFavorite(propertyId) {
  let user = JSON.parse(localStorage.getItem('tokenData'))
  const requestOptions = {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'Content-Type': 'application/json'
    }
  }
  const url = config.realEstatesUrl + '/favorites/add'
  return axios.post(url, propertyId, requestOptions)
}

// delete Property to Favorite
// APi
function deleteFromFavorite(data) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/favorites/delete`
  return axios.post(url, data, requestOptions)
}

// User -------------------------------------------------------
function getAllUsers() {
  const url = `${config.apiUrl}/getAllUsers`
  return axios.get(url)
}

async function updateProfile(user) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.accountsUrl}/updateUser`
  return await axios.post(url, user, requestOptions)
}

///resend Code
function resendCode(phoneNumber) {
  let data = { phoneNumber: phoneNumber }
  const url = config.apiUrl + '/resend-sms'
  return axios.post(url, data)
}

//Get User add properties quota
function getAddPropertiesQuota(userId) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.usersUrl}/users/${userId}/ad-quota`
  return axios.get(url, requestOptions)
}

async function updateProfileData(user) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.usersUrl}/users/update/${user._id}`
  return await axios.put(url, user, requestOptions)
}

//  contact Sales
function contactSales(data) {
  const url = `${config.usersUrl}/admins/lead/add`
  return axios.post(url, data)
}

//get about us
function getAboutUs() {
  const url = `${config.usersUrl}/content/AboutHectar?platform=hectar`
  return axios.get(url)
}
