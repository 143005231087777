'use strict'

import Vue from 'vue'
export const EventBus = new Vue()

// export const EventBus = {};
// const bus = new Vue();
// EventBus.install = function(Vue, _options) {
//     Vue.prototype.$bus = bus;
// };
