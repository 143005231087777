import { userService } from '../../services'

const state = {
  allUsers: {},
  spinnerLoading: false
}

const actions = {
  delete({ commit }, id) {
    commit('deleteRequest', id)
    userService.delete(id).then(
      () => commit('deleteSuccess', id),
      (error) => commit('deleteFailure', { id, error: error.toString() })
    )
  }
}

const mutations = {
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.allUsers.items = state.allUsers.items.map((user) =>
      user.id === id ? { ...user, deleting: true } : user
    )
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.allUsers.items = state.allUsers.items.filter((user) => user.id !== id)
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.allUsers.items = state.items.map((user) => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        // const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { deleteError: error }
      }

      return user
    })
  },
  setSpinnerLoading(state, isLoading) {
    state.spinnerLoading = isLoading
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
