import axios from 'axios'
import _ from 'lodash'
import config from '../config'
import { authHeader } from '../store/helpers'

export const realEstateService = {
  addRealEstate,
  refreshRealEstate,
  updateRealEstate,
  deleteRealEstate,
  getRealEstate,
  ownerRealEstates,
  getSingleProperty,
  generateQuery,
  getCurrentUrlParamsObj,
  addLike,
  checkLike,
  viewsCount,
  validateAdvertiser,
  getInfowindowOffset,
  rateProperty,
  addRequest,
  numberFormatter,
  numberWithCommas,
  getRelatedProperties,
  getRealEstateFeatures,
  hasPurpose,
  getUserRequests,
  contactUs,
  getFAQs,
  getTerms,
  getPrivacyPolicy,
  getPrivacy,
  getUserNotifications,
  setNotificationsState,
  setNotificationState,
  userNotificationsState,
  getAgents,
  getRealEstateStatuses,
  getRealEstateTypes,
  getRealEstatePurposes,
  getRealEstatePopulationTypes,
  uploadRealEstateImage,
  deleteRealEstateImage,
  uploadRealEstateBlueprint,
  getOwnerDetails,
  likesCount,
  getRealestateCount,
  getRatingOptions,
  shareRealEstate
}

function getUserRequests() {
  const url = `${config.apiUrl}/realEstate/userRequests`
  const requestOptions = {
    headers: authHeader()
  }
  return axios.get(url, requestOptions)
}
function getRatingOptions() {
  const url = `${config.realEstatesUrl}/realEstate/ratingOptions`
  const requestOptions = {
    headers: authHeader()
  }
  return axios.get(url, requestOptions)
}
function getRealestateCount(id) {
  const url = `${config.realEstatesUrl}/realEstates/count?account=${id}`
  const requestOptions = {
    headers: authHeader()
  }
  return axios.get(url, requestOptions)
}

function validateAdvertiser(body) {
  const url = `${config.realEstatesUrl}/realEstate/validate-advertiser`
  const requestOptions = {
    headers: authHeader()
  }
  return axios.post(url, body, requestOptions)
}

// Add RealEstate
function addRealEstate(realEstateData) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.realEstatesUrl + '/realEstates/add'
  return axios.post(url, realEstateData, requestOptions)
}

// Add RealEstate image
function uploadRealEstateImage(image) {
  const requestOptions = {
    headers: authHeader('multipart/form-data')
  }
  const url = config.realEstatesUrl + '/realEstates/upload-image'
  return axios.post(url, image, requestOptions)
}
// Add RealEstate image
function uploadRealEstateBlueprint(image) {
  const requestOptions = {
    headers: authHeader('multipart/form-data')
  }
  const url = config.realEstatesUrl + '/realEstates/upload-blueprint'
  return axios.post(url, image, requestOptions)
}

// delete RealEstate image
function deleteRealEstateImage(imageKey, smallImageKey) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstates/images/delete`
  return axios.put(url, { imageKey, smallImageKey }, requestOptions)
}

//getAgents
function getAgents({ lat = 24.7135517, lng = 46.67529569999999 }) {
  const requestOptions = {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  }
  return axios.get(
    `${config.usersUrl}/users/get-realestate-agents?lat=${lat}&lng=${lng}&radius=5000`,
    requestOptions
  )
}

// Update RealEstate
function refreshRealEstate(id) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstate/refresh?_id=${id}`
  return axios.get(url, requestOptions)
}

// Update RealEstate
function updateRealEstate(realEstateData) {
  const requestOptions = {
    headers: authHeader()
  }

  const url = `${config.realEstatesUrl}/realEstates/edit`
  return axios.post(url, realEstateData, requestOptions)
}

// Delete RealEstate
function deleteRealEstate(id) {
  const requestOptions = {
    headers: authHeader()
  }
  const data = { id: id }
  const url = `${config.realEstatesUrl}/realEstates/delete`
  return axios.post(url, data, requestOptions)
}

function ownerRealEstates(query = 'pageSize=10&pageNumber=1') {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstates?${query}`
  return axios.get(url, requestOptions)
}

// Get RealEstate
function getRealEstate(urlParams) {
  const url = `${config.realEstatesUrl}/realEstates?${urlParams}`
  return axios.get(url)
}
// Get RealEstate Statuses
function getRealEstateStatuses() {
  const url = config.realEstatesUrl + '/realestateStatus/getAll'
  return axios.get(url)
}

//Get real-estate types
function getRealEstateTypes() {
  const url = config.realEstatesUrl + '/realestatetypes/getAll'
  return axios.get(url)
}

// Get Single Property Details
function getSingleProperty(propertyId) {
  const url = `${config.realEstatesUrl}/realEstates/${propertyId}`
  return axios.get(url)
}

// Get Related Properties
function getRelatedProperties(propertyId) {
  const url =
    config.realEstatesUrl + '/realEstate/suggestion/?_id=' + propertyId
  return axios.get(url)
}

function getRealEstateFeatures() {
  const url = config.realEstatesUrl + '/realestatefeatures/getAll'
  return axios.get(url)
}
function getRealEstatePurposes() {
  const url = config.realEstatesUrl + '/realestatepurposes/getAll'
  return axios.get(url)
}
function getRealEstatePopulationTypes() {
  const url = config.realEstatesUrl + '/realestatepopulation/getAll'
  return axios.get(url)
}

// Like Property serices -----------------------------------------------

// Add Like
function addLike(realestate, user) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstate/like`
  return axios.post(url, { realestate, user }, requestOptions)
}

// Check Like
function checkLike(propertyId, user) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstate/checkLike`
  return axios.post(url, { realestate: propertyId, user }, requestOptions)
}

// Check Like
function likesCount(realestate) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstate/likes/${realestate}`
  return axios.get(url, requestOptions)
}

function viewsCount(propertyId) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const url = config.realEstatesUrl + '/realEstate/show'
  return axios.post(url, { _id: propertyId }, requestOptions)
}

// Rate Property
function rateProperty(data) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/realEstate/rateRealEstate`
  return axios.post(url, data, requestOptions)
}

// Rate Property
function addRequest(data) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.apiUrl}/realEstate/addRequest`
  return axios.post(url, data, requestOptions)
}

// HelpCenter
function contactUs(info) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.apiUrl + '/addUserQuestion'
  return axios.post(url, info, requestOptions)
}

// HelpCenter
function getFAQs() {
  const url = config.apiUrl + '/getFAQs'
  return axios.get(url)
}

//getTerms
function getTerms() {
  const url = config.apiUrl + '/terms-and-conditions'
  return axios.get(url)
}

function getPrivacyPolicy() {
  const url = config.apiUrl + '/privacy-policies'
  return axios.get(url)
}

//getPrivacy
function getPrivacy() {
  const url = config.apiUrl + '/terms-and-conditions'
  return axios.get(url)
}
// Get User Notifications
function getUserNotifications() {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.apiUrl + '/realEstate/userNotifications'
  return axios.get(url, requestOptions)
}

// Set User Notifications Status
function setNotificationsState() {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.apiUrl + '/realEstate/setNotificationsState'
  return axios.get(url, requestOptions)
}

// Set User Notifications Status
function setNotificationState(options) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.apiUrl + '/realEstate/setNotificationState'
  return axios.post(url, options, requestOptions)
}

// Get User Notifications Status
function userNotificationsState() {
  const requestOptions = {
    headers: authHeader()
  }
  const url = config.apiUrl + '/realEstate/userNotifications'
  return axios.get(url, requestOptions)
}

// helper functions
function paramsToObject(entries) {
  let result = {}
  for (let entry of entries) {
    // each 'entry' is a [key, value] tupple
    const [key, value] = entry
    result[key] = value
  }
  return result
}

// Retuen Url params as an object
function getCurrentUrlParamsObj() {
  let query = location.search.substring(1)
  const urlParams = new URLSearchParams(query)
  const entries = urlParams.entries() //returns an iterator of decoded [key,value] tuples
  const paramsObj = paramsToObject(entries) //{abc:"foo",def:"[asf]",xyz:"5"}
  return paramsObj
}

async function generateQuery(filters) {
  // debugger;
  let urlParamsObje = getCurrentUrlParamsObj()
  filters = { ...urlParamsObje, ...filters } // In the case of a key duplication: filers prop overwrite urlParamsObje
  let query = ''
  let urlQuery = ''
  let excludeParams = ['lat', 'lng', 'placeAddress']
  _.map(filters, function (value, key) {
    //debugger; // To review if conditions
    if (
      (value && !value.isArray && value != '' && value != 0) ||
      (value && value.isArray && value.length)
    ) {
      if (!excludeParams.includes(key)) {
        query += `${key}=${value}&`
        urlQuery += `${key}=${value}&`
      } else {
        urlQuery += `${key}=${value}&`
      }
    }
  })
  // await router.push({name: 'properties', params: {page: 'default'}});
  window.history.replaceState(
    {},
    document.title,
    '/properties/default?' + urlQuery
  ) // Update URL
  return query
}

// Map InfoWindow Functions --------------------------------------
function getInfowindowOffset(map, marker) {
  var center = getPixelFromLatLng(map, map.getCenter()),
    point = getPixelFromLatLng(map, marker.getPosition()),
    quadrant = '',
    offset
  quadrant += point.y > center.y ? 'b' : 't'
  quadrant += point.x < center.x ? 'l' : 'r'
  if (quadrant == 'tr') {
    offset = new window.google.maps.Size(-100, 245)
  } else if (quadrant == 'tl') {
    offset = new window.google.maps.Size(100, 245)
  } else if (quadrant == 'br') {
    offset = new window.google.maps.Size(-100, -15)
  } else if (quadrant == 'bl') {
    offset = new window.google.maps.Size(100, -15)
  }

  return offset
}

function getPixelFromLatLng(map, latLng) {
  var projection = map.getProjection()
  var point = projection.fromLatLngToPoint(latLng)
  return point
}

// Format Number ----------------------------
// URL: https://stackoverflow.com/a/9462382/1551034
function numberFormatter(num, digits) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' الف' }, // K
    { value: 1e6, symbol: ' مليون' }, // M
    { value: 1e9, symbol: ' مليار' }, // G
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

// How to print a number with commas as thousands separators
// url: https://stackoverflow.com/a/2901298/1551034
function numberWithCommas(n) {
  // if (x)
  //     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // else return x;
  return parseFloat(n).toLocaleString()
}

// Check if Property Type has required purpose
function hasPurpose(type) {
  return config.typesPurposeIsRequired.indexOf(type) == -1 ? false : true
}
function getOwnerDetails(userId) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.usersUrl}/users/get/${userId}`
  return axios.get(url, requestOptions)
}

function shareRealEstate(data) {
  const requestOptions = {
    headers: authHeader()
  }
  const url = `${config.realEstatesUrl}/sharing`
  return axios.post(url, data, requestOptions)
}
