import { authRoutes } from './auth.routes'
import { homeRoutes } from './home.routes'
import { userRoutes } from './user.routes'
import { pagesRoutes } from './pages.routes'

const routes = [
  // path: '/',
  // component: RouterLayout,
  // children: [
  ...homeRoutes,
  ...authRoutes,
  ...userRoutes,
  ...pagesRoutes,
  {
    path: '*',
    name: '404',
    meta: {
      title: 'الصفحة غير موجودة',
      showFooter: true,
      secondHeader: false
    },
    component: () => import('@/views/Page404.vue')
  },
  {
    path: '*',
    name: 'property-404',
    meta: {
      title: 'العقار غير موجود',
      showFooter: true,
      secondHeader: false
    },
    component: () => import('@/views/Page404-property.vue')
  }
  // ],
]

export default routes
