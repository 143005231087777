import { makeMutations, makeActions } from '@/store/helpers/default.helper'

const defaultState = {}

const mutations = {}

export default {
  namespaced: true,
  state: defaultState,
  mutations: makeMutations(defaultState, mutations),
  actions: makeActions(defaultState)
}
