import { realEstateService } from '../../services'
import {
  makeMutations,
  makeActions,
  makeGetters
} from '@/store/helpers/default.helper'

const defaultState = {
  propertiesFound: [],
  numberOfRealEstates: null,
  mapCenter: { lat: 24.7135517, lng: 46.67529569999999 }, // Riyadh is default
  mapZoom: 12,
  listingsTitle: '',
  // realEstateStatus: {},
  // realEstateTypes: configs ? configs.realEstateTypes : {},
  previewedImages: [],
  pageSize: 12,
  mapEventsFlag: true,
  urlQuery: '',
  visitedProps: [],
  step1_Percentage: 0,
  step2_Percentage: 0,
  step3_Percentage: 0,
  step4_Percentage: 0,
  step5_Percentage: 0,
  completePercentage: 0,

  isDisplayedOnce: false
}

const getters = {
  propertyLayouts: (state) => (id) =>
    state.properties.find((item) => item.id === id).layouts,
  totalCompletePercentage: (state) =>
    state.step1_Percentage +
    state.step2_Percentage +
    state.step3_Percentage +
    state.step4_Percentage +
    state.step5_Percentage
}

const actions = {
  addRealEstate(context, realEstateData) {
    return realEstateService.addRealEstate(realEstateData)
  },
  updateRealEstate(context, realEstateData) {
    realEstateData.model._id = realEstateData.id
    return realEstateService.updateRealEstate(realEstateData.model)
  },

  getRealEstate({ commit }, urlParams) {
    // return
    realEstateService
      .getRealEstate(urlParams)
      .then(
        (response) => {
          commit('userState/setSpinnerLoading', false, { root: true })
          commit('setPropertiesFound', response.data.realEstates)
          commit('setNumberOfRealEstates', response.data.count)
        },
        (error) => {
          console.log(error)
          commit('userState/setSpinnerLoading', false, { root: true })
        }
      )
      .catch((error) => {
        console.log(error)
        commit('userState/setSpinnerLoading', false, { root: true })
      })
  },

  getSingleProperty(propertyId) {
    return realEstateService.getSingleProperty(propertyId)
  }
}

const mutations = {
  setPropertiesFound(state, properties) {
    state.propertiesFound = properties
  },
  setNumberOfRealEstates(state, number) {
    state.numberOfRealEstates = number
  },
  previewedImages: (state, imageSrc) => {
    state.previewedImages.push(imageSrc)
    console.log('previewed images', state.previewedImages)
  },
  deletePreviewedImage: (state, imageSrc) => {
    state.previewedImages = state.previewedImages.filter((image) => {
      return image != imageSrc
    })
    console.log('previewed images', state.previewedImages)
  },
  deletePreviewedImages: (state) => {
    state.previewedImages = []
  },
  refreshRealEstate: (state, id) => {
    for (const property of state.propertiesFound) {
      if (property._id === id) {
        property.active = 1
      }
    }
  },
  toggleLayoutVisible(state, { propertyId, layoutIndex }) {
    var prope = state.properties.find((item) => item.id === propertyId)
    var layout = prope.layouts[layoutIndex]
    layout.visible = !layout.visible
  },
  deleteThisProperty(state, propertyId) {
    let deletedIndex = -1
    for (var i = 0; i < state.properties.length; i += 1) {
      if (state.properties[i]['id'] === propertyId) {
        deletedIndex = i
      }
    }
    state.properties.splice(deletedIndex, 1)
  },
  setMapCenter(state, location) {
    state.mapCenter = location
  },
  setMapZoom(state, zoom) {
    state.mapZoom = zoom
  },
  setPropertiesTitle(state, title) {
    state.listingsTitle = title
  },
  setMapEventsFlag(state, value) {
    state.mapEventsFlag = value
  },
  setUrlQuery(state, query) {
    state.urlQuery = query
  },

  setStep1_Percentage(state, val) {
    state.step1_Percentage = val
  },
  setStep2_Percentage(state, val) {
    state.step2_Percentage = val
  },
  setStep3_Percentage(state, val) {
    state.step3_Percentage = val
  },
  setStep4_Percentage(state, val) {
    state.step4_Percentage = val
  },
  setStep5_Percentage(state, val) {
    state.step5_Percentage = val
  },

  addtoVisitedProps: (state, id) => {
    state.visitedProps.push(id)
  },

  setIsDisplayedOnce: (state, value) => {
    state.isDisplayedOnce = value
  }
}

export default {
  namespaced: true,
  state: defaultState,
  mutations: makeMutations(defaultState, mutations),
  actions: makeActions(defaultState, actions),
  getters: makeGetters(defaultState, getters)
}
