import { realEstateService } from '../../services'

const state = {
  agentLocator: true,
  agents: []
}

const mutations = {
  setAgentLocator(state, paylode) {
    state.agentLocator = paylode
  },
  setAgents(state, paylode) {
    state.agents = paylode
  }
}
const actions = {
  getAgents({ commit }, urlParams) {
    commit('userState/setSpinnerLoading', true, { root: true })
    return new Promise((resolve) => {
      realEstateService.getAgents(urlParams).then((res) => {
        commit('setAgents', res.data.results)
        resolve(res.data.results)
        commit('userState/setSpinnerLoading', false, { root: true })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
