import Vue from 'vue'
import VueWait from 'vue-wait'

import vueMeta from 'vue-meta'

// Config File
import config from './config'

// api
import axios from './api'

// Map
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    region: 'SA',
    key: config.googleMapApiKey, // Add your here your google map api key
    libraries: 'places',
    // v: 3.38,
    language: 'ar' //store.getters.selectedLocale.locale
  },
  installComponents: true
})

// VueSocket
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
const socket = io(process.env.VUE_APP_SOCKET_URL)
Vue.use(VueSocketIOExt, socket)

//https://github.com/xkjyeah/vue-google-maps/issues/675

// Rating
import rate from 'vue-rate'
Vue.use(rate)

// Loading placeholder
import VueContentPlaceholders from 'vue-content-placeholders'
Vue.use(VueContentPlaceholders)

// Import bootstrap Vue -------------------------
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// bootstrap styles defiened in app.vue <style>

// Toasted Notification -------------------------
import Toasted from 'vue-toasted'
Vue.use(Toasted, { iconPack: 'custom-class', duration: 1000 })

// Import Theme styles --------------------------
import './styles/styles.scss'

import './plugins/main'
import i18n from './plugins/i18n.plugin' // Internationalization
import './plugins/vuex-router-sync.plugin'

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Vue Media Query
import VueMq from 'vue-mq'
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    desktop: Infinity
  },
  defaultBreakpoint: 'md' // customize this for SSR
})

// Vue Sticky Kit
import VueStickyKit from 'vue-stickykit'
Vue.use(VueStickyKit)

// Vue Dialog
import VuejsDialog from 'vuejs-dialog'
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
// Tell Vue to install the plugin.
Vue.use(VuejsDialog)

// PerfectScrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)
import store from './store'

import App from './App.vue'
import router from './router'
//Hotjar
import Hotjar from 'vue-hotjar'

//share
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)

Vue.use(Hotjar, {
  id: '1768824',
  isProduction: true,
  snippetVersion: 6
})
Vue.config.productionTip = false

Vue.use(vueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

export default new Vue({
  i18n,
  store,
  router,
  axios,
  render: (h) => h(App),
  wait: new VueWait({
    useVuex: true
  })
}).$mount('#app')
