import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
// import store from '@/store'
import i18n from './../plugins/i18n.plugin'
import routes from './routes'
import vueMeta from 'vue-meta'

const router = new VueRouter({
  mode: 'history',
  base: '/', //process.env.BASE_URL,
  routes,
  linkActiveClass: 'current',
  linkExactActiveClass: 'exact-current'
})

Vue.use(VueRouter)

Vue.use(vueMeta)


router.beforeEach((to, from, next) => {
  function redirectToLogin() {
    next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }
  Vue.prototype.$toasted.clear()

  // Store Last Visited page info (name and fullpath)
  // Used in Bact to button on properties map
  // let currentVisitedPage = JSON.parse(localStorage.getItem('currentVisitedPage'))
  // localStorage.setItem('lastVisitedPage', JSON.stringify(currentVisitedPage) )

  // If this isn't an initial page load...
  if (from.name !== null) {
    NProgress.start()
  }

  /* FIXME: use https://github.com/declandewet/vue-meta,
  https://stackoverflow.com/questions/36612847/how-can-i-bind-the-html-title-content-in-vuejs#answer-40388120
  https://github.com/vuejs/vue-router/issues/914#issuecomment-376719306 */
  document.title = to.meta.title || i18n.t('app.title')

  const authRequired = to.matched.some((record) => record.meta.authRequired)
  const token = localStorage.getItem('tokenData')

  if (authRequired && !token && to.name !== 'login') {
    redirectToLogin()
  } else {
    next()
  }
})

// When each route is finished evaluating...
router.afterEach(() => {
  // Store Current Visited page
  // localStorage.setItem('currentVisitedPage', JSON.stringify({name: to.name, path: to.fullPath , id: to.params.id}) )

  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
