<template>
  <div>
    <div id="footer" class="btoo">
      <!--to make the footer sticky - add class="sticky-footer"-->
      <!-- Main -->
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-2">
            <router-link to="/"
              ><img
                class="footer-logo"
                src="../../assets/images/logo-light.svg"
                alt="Site Logo"
                loading="lazy"
            /></router-link>
            <br /><br />
          </div>

          <div class="col-md-3">
            <b-nav vertical class="footer-links">
              <b-nav-item v-if="!isLoggedIn" to="/auth/login"
                >تسجيل الدخول</b-nav-item
              >
              <b-nav-item v-if="isLoggedIn" :to="{ name: 'profile' }"
                >حسابي</b-nav-item
              >
              <b-nav-item @click="addNewProperty"> اضف عقارك</b-nav-item>
            </b-nav>
          </div>

          <div class="col-lg-4 col-md-4">
            <b-nav vertical class="footer-links">
              <b-nav-item to="/help-center" target="_blank"
                >مركز المساعده</b-nav-item
              >
              <b-nav-item to="/terms&conditions" target="_blank"
                >الشروط والأحكام وسياسه الإستخدام</b-nav-item
              >
              <b-nav-item to="/about" target="_blank">عن هكتار</b-nav-item>
            </b-nav>
          </div>

          <div class="col-lg-2 col-md-3">
            <div class="app-links">
              <a
                href="https://play.google.com/store/apps/details?id=com.hectar.hectarapp"
                target="_blank"
                ><img :src="googlePlayApp" alt="Google App Downlaod Icon"
              /></a>
              <a
                href="https://apps.apple.com/eg/app/hectar-%D9%87%D9%83%D8%AA%D8%A7%D8%B1/id1560571291"
                target="_blank"
                ><img :src="iosApp" alt="Apple App Downlaod Icon"
              /></a>
            </div>
          </div>
        </div>

        <!-- Copyright -->
        <div class="row">
          <div class="col-md-12">
            <div class="copyrights">هكتار - كافة الحقوق محفوظة © 2022</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="btn btn--white btn--shadow footer-back-btn" id="gps-btn-2" variant="light" @click="backBtn"><span class="hi-arrow-right icon ml-2"></span> عودة</button> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'

export default {
  name: 'MainFooter',
  data() {
    return {
      googlePlayApp: require('../../assets/images/google-play-badge.svg'),
      iosApp: require('../../assets/images/app-store-badge.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'status', 'loggedIn']),

    ...mapState('realEstateState', ['propertiesFound']),

    isLoggedIn() {
      return this.status.loggedIn
    },
    iosAppUrl() {
      return config.iosAppUrl
    },
    androidAppUrl() {
      return config.androidAppUrl
    }
  },
  methods: {
    addNewProperty() {
      let isLoggedInFromStorage = localStorage.getItem('logged_in')
      if (isLoggedInFromStorage !== 'true') {
        this.$toasted.show('من فضلك قم بتسجيل الدخول أولاً', {
          duration: 4000
        })
        if (this.$route.name !== 'login') this.$router.push({ name: 'login' })
      } else if (this.user?.type === 'seeker') {
        this.$toasted.show(
          'نوع المستخدم باحث عن عقار لا يمكنه إضافة عقارات، فضلاً قم بتغيبر نوع الحساب من إعدادات ملفك الشخصي',
          {
            duration: 5000,
            icon: 'hi-close',
            position: 'top-center',
            type: 'error',
            action: [
              {
                text: 'من هنا',
                onClick: (e, toastObject) => {
                  this.$router.push('/profile')
                  toastObject.goAway(0)
                }
              }
            ]
          }
        )
      } else {
        this.$router.push({ name: 'addProperty', params: { id: 'new' } })
      }
    },
    backBtn() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
@media (max-width: 760px) {
  .btn#gps-btn-2 {
    margin-bottom: 95px;
    z-index: 10000;
  }
}
</style>
