import router from '../../router'
import { realEstateService, userService } from '../../services'

const user = JSON.parse(localStorage.getItem('tokenData'))
const configs = JSON.parse(localStorage.getItem('configrations'))
const state = user
  ? {
      status: { loggedIn: true },
      loggedIn: true,
      user,
      configs,
      configsIsLoaded: false,
      profile: {},
      isAuthLoading: false,
      favorites: [],
      favoritesIds: [],
      realEstateStatus: {},
      realEstateTypes: {},
      realEstateFeatures: {},
      realEstatesQuota: null
    }
  : {
      status: { loggedIn: false },
      loggedIn: false,
      favorites: [],
      user: null,
      configs,
      configsIsLoaded: false,
      profile: null
    }

const getters = {
  getConfigNameById: state => (
    fieldName,
    lang = 'Ar',
    id,
    innerFieldName = null,
    innerId = null
  ) => {
    let configs = state.configs
    let item = configs[fieldName].find(item => item._id == id)
    if (item) {
      if (innerFieldName && innerId) {
        let innerOption = item[innerFieldName].find(item => item._id == innerId)
        return innerOption[`name${lang}`]
      }
      return item[`name${lang}`]
    } else {
      return ''
    }
  },
  getConfigNameByCode: state => (
    fieldName,
    lang = 'Ar',
    code,
    innerFieldName = null,
    innerId = null
  ) => {
    let configs = state.configs
    let item = configs[fieldName].find(item => item.code === code)
    if (item) {
      if (innerFieldName && innerId) {
        let innerOption = item[innerFieldName].find(item => item._id == innerId)
        return innerOption[`name${lang}`]
      }
      return item[`name${lang}`]
    } else {
      return ''
    }
  },
  getConfigIdByName: state => (fieldName, nameEn) => {
    let configs = state.configs
    let item = configs[fieldName].find(item => item.nameEn == nameEn)
    if (item) {
      return item._id
    } else {
      return ''
    }
  },
  getRatingOptions: state => {
    if (state.configs.ratingOptions) {
      return state.configs.ratingOptions
    }
    return []
  },
  getUserTypes: state => {
    return state.userTypes
  }
}

const actions = {
  login({ phoneNumber, password }) {
    return userService.login(phoneNumber, password)
  },
  logout({ commit }) {
    localStorage.removeItem('tokenData')
    localStorage.setItem('logged_in', 'false')

    router.push('/auth/login')
    commit('logout')
  },

  // Regsiter new user
  checkPhoneNumber(context, phoneNumber) {
    return userService.checkPhoneNumber(phoneNumber)
  },
  confirmPhoneNumber(context, data) {
    return userService.confirmPhoneNumber(data)
  },
  register({ commit }, user) {
    commit('registerRequest', user)
    return userService.register(user)
  },
  createUser({ commit }, user) {
    commit('createUserRequest', user)
    return userService.createUser(user)
  },

  // update
  updateProfile(context, userData) {
    return userService.updateProfile(userData)
  },

  updateProfileData({ commit, state }, user) {
    return userService.updateProfileData(user).then(res => {
      const updatedUser = { ...res.data.user, token: state.user.token }
      commit('updateUser', updatedUser)
      localStorage.setItem('tokenData', JSON.stringify(updatedUser))
    })
  },

  // Forget/Reset password
  checkPhoneNumberForResetPassword(context, phoneNumber) {
    return userService.checkPhoneNumberForResetPassword(phoneNumber)
  },
  confirmForgetCode(context, data) {
    return userService.confirmForgetCode(data)
  },
  resetPassword(context, data) {
    return userService.resetPassword(data)
  },

  // Configration
  async getConfigrations({ commit }) {
    let realEstateStatuses = await realEstateService.getRealEstateStatuses()
    if (realEstateStatuses.data.realEstateStatus) {
      let statuses = [
        { _id: 0, nameAr: 'الكل' },
        ...realEstateStatuses.data.realEstateStatus
      ]
      commit('setRealEstateStatus', statuses)
    }
    let realEstateTypes = await realEstateService.getRealEstateTypes()
    if (realEstateTypes.data.realestateTypes) {
      let types = [
        { _id: 0, nameAr: 'الكل' },
        ...realEstateTypes.data.realestateTypes
      ]
      commit('setRealEstateTypes', types)
    }
    let realEstateFeatures = await realEstateService.getRealEstateFeatures()
    if (realEstateFeatures.data.realestateFeatures) {
      commit(
        'setRealEstateFeatures',
        realEstateFeatures.data.realestateFeatures
      )
    }
    let realEstatePurpose = await realEstateService.getRealEstatePurposes()
    if (realEstatePurpose.data.realestatePurpose) {
      commit('setRealEstatePurpose', realEstatePurpose.data.realestatePurpose)
    }
    let realEstatePopulationType = await realEstateService.getRealEstatePopulationTypes()
    if (realEstatePopulationType.data.realestatePopulations) {
      commit(
        'setRealEstatePopulationType',
        realEstatePopulationType.data.realestatePopulations
      )
    }

    // ratingOptions
    let ratingOptions = await realEstateService.getRatingOptions()

    let userTypes = await userService.getUserTypes()
    if (userTypes.data.userTypes) {
      commit('setUserTypes', userTypes.data.userTypes)
    }
    commit('setConfigsLoadedState', true)
    const configurations = {
      ratingOptions: ratingOptions.data.ratingOptions,
      realEstateTypes: realEstateTypes.data.realestateTypes,
      realEstateFeatures: realEstateFeatures.data.realestateFeatures,
      realEstatePurpose: realEstatePurpose.data.realestatePurpose,
      realEstatePopulationType:
        realEstatePopulationType.data.realestatePopulations,
      ...realEstateStatuses.data,
      ...userTypes.data
    }
    localStorage.setItem('configrations', JSON.stringify(configurations))
    commit('setConfigrations', configurations)
    // userService.getConfigrations().then(configs => {
    //     commit('setConfigsLoadedState', true);
    //     commit('setConfigrations', configs);
    //     let statuses = [{ _id: 0, nameAr: 'الكل'}, ...configs.data.realEstateStatus];
    //     let types = [{ _id: 0, nameAr: 'الكل'}, ...configs.data.realEstateTypes];
    //     commit('setRealEstateStatus', statuses);
    //     commit('setRealEstateTypes', types);
    //     commit('setRealEstateFeatures', configs.data.realEstateFeatures);
    // });
  },

  // Favorites -------------------------------------
  getFavorites({ commit, state }) {
    if (state.loggedIn) {
      userService.getFavorites().then(favorites => {
        if (favorites.data.fav.length) {
          let myfavorites = favorites.data.fav
          if (myfavorites) {
            commit('setFavorites', favorites.data.fav)
          }
        }
      })
    }
  },
  addToFavorite(context, property) {
    return userService.addToFavorite(property)
  },
  deleteFromFavorite(context, property) {
    return userService.deleteFromFavorite(property)
  },
  async getRealEstatesQuota({ commit, state }) {
    await userService.getAddPropertiesQuota(state.user._id).then(res => {
      commit('setRealEstatesQuota', res.data.user.adQuota)
    })
  }
}

const mutations = {
  setLoggedInStatus(state, isLoggedIn) {
    state.loggedIn = isLoggedIn
  },
  loginSuccess(state, user) {
    state.loggedIn = true
    state.status = { loggedIn: true }
    state.user = user
  },
  updateUser(state, user) {
    state.user = user
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.loggedIn = false
    state.user = null
    state.favorites = []
  },
  otpConfirmed(state, confirmed) {
    state.status = { otpConfirmed: confirmed }
  },
  registerRequest(state) {
    state.status = { registering: true }
  },
  registerSuccess(state) {
    state.status = {}
  },
  registerFailure(state) {
    state.status = {}
  },
  setConfigrations(state, configs) {
    state.configs = configs
  },
  setFavorites(state, favorites) {
    let mapedFav = favorites.filter(item => item != null)
    state.favorites = mapedFav
    state.favoritesIds = state.favorites.map(item => item.realestate._id)
  },
  addToFavorites(state, property) {
    state.favorites = [...state.favorites, property]
    state.favoritesIds.push(property.realestate._id)
  },
  deleteFromFavorites(state, data) {
    let { favoritesIds, favorites } = state
    //find the id of the favorite to delete it
    const favourite = favorites.find(item => {
      return item.realestate._id === data.realestate
    })
    state.favorites = favorites.filter(item => item._id != favourite._id)
    state.favoritesIds = favoritesIds.filter(id => id != favourite._id)
  },

  setRealEstateStatus: (state, statuses) => {
    state.realEstateStatus = statuses
  },
  setRealEstateFeatures: (state, features) => {
    state.realEstateFeatures = features
  },
  setRealEstateTypes: (state, types) => {
    state.realEstateTypes = types
  },
  setRealEstatePurpose: (state, purposes) => {
    state.realEstatePurpose = purposes
  },
  setRealEstatePopulationType: (state, types) => {
    state.realEstatePopulationType = types
  },
  setConfigsLoadedState(state, isLoaded) {
    state.configsIsLoaded = isLoaded
  },
  setUserTypes: (state, types) => {
    state.userTypes = types
  },
  setRealEstatesQuota(state, quota) {
    state.realEstatesQuota = quota
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
