'use strict'

export const pagesRoutes = [
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/Contact.vue'),
    meta: {
      title: 'تواصل معنا',
      showFooter: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/Faq.vue'),
    meta: {
      title: 'الأسئلة المتكررة',
      showFooter: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/pages/termsAndConditions.vue'),
    meta: {
      title: 'الشروط والاحكام',
      showFooter: true
    }
  },
  {
    path: '/privacy-and-policy',
    name: 'privacy-and-policy',
    component: () => import('@/views/pages/privacyPolicy.vue'),
    meta: {
      title: 'الشروط والاحكام',
      showFooter: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/pages/AboutHectar.vue'),
    meta: {
      title: 'عن هكتار',
      showFooter: true
    }
  },
  {
    path: '/add-property/:id',
    name: 'addProperty',
    component: () => import('@/views/property/AddProperty.vue'),
    beforeEnter: (to, from, next) => {
      //store.dispatch('accountState/getRealEstatesQuota')
      next()
    },
    meta: {
      title: 'إضافة عقار جديد',
      showFooter: true,
      authRequired: true
    }
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('@/views/pages/helpCenter.vue'),
    meta: {
      title: 'مركز المساعده',
      showFooter: true
    }
  },
  {
    path: '/terms&conditions',
    name: 'terms&conditions',
    component: () => import('@/views/pages/TermsConditions.vue'),
    meta: {
      title: 'الشروط والأحكام',
      showFooter: false
    }
  }
]
