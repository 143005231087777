<template>
  <!-- configsIsLoaded:: In first load stop displaying content until configs is loaded correctly -->
  <div
    :class="{ 'online-connection': isOnline, 'offline-connection': !isOnline }"
    v-if="configsIsLoaded"
  >
    <b-alert
      fade
      variant="danger"
      show
      v-if="!isOnline"
      class="connections-message"
      >عفواً، هناك مشكلة في الإتصال بالإنترنت <strong></strong
    ></b-alert>
    <div class="wrapper">
      <div class="loading-wrap">
        <div class="inner-wrap" :class="{ isActive: loading }">
          <circle2></circle2>
        </div>
      </div>

      <!-- Header -->
      <main-header v-if="showHeader" />

      <transition
        name="fade-transform"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @afterEnter="afterEnter"
      >
        <router-view :key="$route.path" />
      </transition>
      <!-- Footer -->
      <div class="footer-div">
      <main-footer v-if="showFooter" />
    </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Circle2 } from 'vue-loading-spinner'
import MainHeader from '@/components/header/MainHeader.vue'
import MainFooter from '@/components/footer/MainFooter.vue'

export default {
  name: 'App',  
  components: { MainHeader, MainFooter, Circle2 },
  data() {
    return {
      loading: false,
      onLine: navigator.onLine,
      isOnline: true,
      linkHectarPlus:
        '<a href="https://hectarplus-development.herokuapp.com/" target="_blank" class="link">هكتار بلس</a>'
    }
  },
  computed: {
    ...mapState('accountState', ['configsIsLoaded', 'user']),
    showFooter() {
      return this.$route.meta.showFooter
    },
    showHeader() {
      return this.$route.name == 'help-center' ||
        this.$route.name == 'terms&conditions'
        ? false
        : true
    }
  },
  methods: {
    ...mapActions('accountState', ['logout']),
    updateOnlineStatus(e) {
      if (e.type === 'offline') {
        this.isOnline = false
      } else if (e.type === 'online') {
        this.isOnline = true
        this.$toasted.show('تم معاودة الإتصال بالإنترنت بنجاح', {
          duration: 7000,
          type: 'success',
          position: 'top-left'
        })
      }
      const { type } = e
      this.onLine = type === 'online'
    },
    beforeLeave() {
      this.loading = true
    },
    afterEnter() {
      this.loading = false
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        const body = document.getElementsByTagName('body')[0]
        if (from !== undefined) {
          body.classList.remove('page--' + from.name)
          body.classList.remove('page--' + from.params.id)
        }
        body.classList.add('page--' + to.name)
        body.classList.add('page--' + to.params.id)
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('accountState/getConfigrations')

    let loggedIn = localStorage.getItem('logged_in')

    if (loggedIn == 'true') {
      this.$store.dispatch('accountState/getFavorites')
    }

    window.addEventListener('storage', function (event) {
      if (event.key === 'logged_in' && loggedIn === false) {
        localStorage.removeItem('tokenData')
      }
    })
  },
  mounted() {
    // check online/offline
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    // SOCKET for logout when an user delete an account from hectar mobile
    this.$socket.$subscribe('Logout', ({ userId }) => {
      if (userId === this.user._id) {
        this.logout()
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
}
</script>

<style>
a.link {
  margin: 0 2px;
  color: white;
  border-bottom: 1px solid white;
}
</style>
