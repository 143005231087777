'use strict'

export function authHeader(contentType = 'application/json') {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('tokenData'))
  if (user && user.token) {
    return {
      Authorization: 'Bearer ' + user.token,
      'Content-Type': contentType,
      client: 'Hectar'
    }
  } else {
    return {}
  }
}
