//
export default {
  // ----------------------------------------------------------------------------------------------------------------
  //------------------------------------micro-services-urls----------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  BASE_URL: process.env.VUE_APP_BASE_URL || 'http://localhost:8080/',
  apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:9000/api',
  accountsUrl: process.env.VUE_APP_ACCOUNTS_URL || 'http://localhost:9091/api',
  messagesUrl: process.env.VUE_APP_MESSAGES_URL || 'http://localhost:9099/api',
  usersUrl: process.env.VUE_APP_USERS_URL || 'http://localhost:9090/api',
  realEstatesUrl:
    process.env.VUE_APP_REALESTATE_URL || 'http://localhost:5000/api',

  // ----------------------------------------------------------------------------------------------------------------
  //------------------------------------googleMap----------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  googleMapApiKey: 'AIzaSyDLDBsRAjpSHIQ_oQlxhrnWVejpPwjA9V0',
  iosAppUrl: 'https://apps.apple.com/us/app/id1492253360',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.hectarapp',
  mapStyles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ededed'
        },
        {
          saturation: -20
        }
      ]
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ebe9e6'
        },
        {
          saturation: -30
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.business',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.government',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#d4eed4'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.school',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#e1e1e1'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e2e2e2'
        }
      ]
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels.icon',
      stylers: [
        {
          color: '#888888'
        }
      ]
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#d3e6f2'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ],

  // ----------------------------------------------------------------------------------------------------------------
  //------------------------------------Config----------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  supportedImageTypes: ['image/png', 'image/jpeg', 'image/jpg'],
  payTypeOptions: [
    { text: 'سنوي', value: 0 },
    { text: 'شهري', value: 1 },
    { text: 'يومي', value: 2 }
  ],
  requestsOptions: [
    { text: 'خدمة التصوير الأحترافي لعقارك', value: 0 },
    { text: 'خدمة تصوير 360 درجة لعقارك', value: 1 },
    { text: 'طباعة كيو ار كود - يتضمن كل عقاراتك', value: 2 }
  ],
  selectedSides: { 1: 'الشرقية', 2: 'الغربية', 3: 'الشمالية', 0: 'الجنوبية' },
  typesPurposeIsRequired: [
    'land',
    'building',
    'compound',
    'Storehouse',
    'Tower',
    'Building'
  ],
  typesForCommercial: ['office', 'store', 'shop', 'workshop'],
  typesForResidential: [
    'flat',
    'floor',
    'room',
    'villa',
    'Chalet',
    'Guest house'
  ],
  typesFields: {
    flat: {
      // Flat
      purpose: true, // سكني
      PopulationType: true, // if status == rent
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      numberOfKitchenUnit: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    floor: {
      // Floor
      purpose: true, // سكني
      PopulationType: true, // if status == rent
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      numberOfKitchenUnit: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    villa: {
      // Villa
      purpose: true, // سكني
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      numberOfKitchenUnit: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Internal garage',
        'Laundry Room',
        'Smoke detector',
        'Swimming pool',
        'gym',
        'Diwaniya',
        'Elevator',
        'Monsters / yard',
        'Garden',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    office: {
      // Office
      purpose: true, // تجاري
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    building: {
      // building
      PopulationType: true,
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      numberOfUnit: true,
      numberOfKitchenUnit: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Internal garage',
        'Laundry Room',
        'Smoke detector',
        'Swimming pool',
        'gym',
        'Diwaniya',
        'Elevator',
        'Monsters / yard',
        'Garden',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    room: {
      // room
      purpose: true, // سكني
      age: true,
      createdAt: true,
      floor: true,
      baths: true,
      halls: true,
      features: [
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    land: {
      // land
      purpose: true,
      streetWidth: true,
      sides: true,
      createdAt: true,
      streetsAround: true,
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    store: {
      // store
      purpose: true, // تجاري
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      features: [
        'Guard',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    shop: {
      // shop
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      createdAt: true,
      features: [
        'Guard',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Private Interance',
        'Internal garage',
        'Smoke detector',
        'Swimming pool',
        'Elevator',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    },
    compound: {
      // compound
      purpose: true, // سكني
      age: true,
      createdAt: true,
      floor: true,
      rooms: true,
      baths: true,
      halls: true,
      numberOfUnit: true,
      numberOfKitchenUnit: true,
      features: [
        'On Street',
        'Guard',
        'Ready kitchen',
        'Window conditioners',
        'Air conditioners',
        'Private parking',
        'Internal garage',
        'Laundry Room',
        'Smoke detector',
        'Swimming pool',
        'gym',
        'Diwaniya',
        'Elevator',
        'Monsters / yard',
        'Garden',
        'Central gas'
      ],
      selectedSides: {
        1: 'الشرقية',
        2: 'الغربية',
        3: 'الشمالية',
        0: 'الجنوبية'
      },
      streetWidth: true,
      length: true,
      width: true,
      selectedAdvertiserType: true,
      authorizationNumber: true
    }
  },
  propertyType: [
    'الكل',
    'شقة',
    'فيلا',
    'دور',
    'عمارة',
    'أرض',
    'مكتب',
    'محل',
    'مجمع سكني'
  ],
  ratingOptions: {
    1: ['المعلومات المعروضة غير لائقة', 'معاملة سيئة', 'معلمات غير صحيحة'],
    2: [
      'ليس هناك رد',
      'إخلاف في المواعيد',
      'معلومات مغلوطة',
      'مواصفات الواقع غير مطابقة للإعلان'
    ],
    3: ['تجاوب جيد', 'إلتزام بالمواعيد', 'التمكن من معاينة العقار'],
    4: ['تجاوب سريع', 'رد سريع', 'مواصفات العقار مطابقة للإعلان'],
    5: [
      'تجاوب رائع',
      'معاملة راقية',
      'تجربة مميزة',
      'مواصفات العقار تفوق التوقع'
    ]
  }
}
