export const userRoutes = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/user/Profile.vue'),
    meta: {
      title: 'الملف الشخصي',
      showFooter: true,
      authRequired: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/user/ChangePassword.vue'),
    meta: {
      title: 'تغيير كلمة المرور',
      showFooter: true,
      authRequired: true
    }
  },
  {
    path: '/messaging',
    name: 'messaging',
    component: () => import('@/views/messages/index.vue'),
    meta: {
      title: 'الرسائل',
      showFooter: true,
      authRequired: true
    }
  }
  ,
  {
    path: '/rent-contract',
    name: 'rent-contract',
    component: () => import('@/views/contracts/rent-contract.vue'),
    meta: {
      title: 'خدمات',
      showFooter: true,
      authRequired: true
    }
  }
]
