export const homeRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: 'الرئيسية',
      showFooter: false
    }
  },
  {
    path: '/properties/:id',
    name: 'properties',
    component: () => import('@/views/home/Properties.vue'),
    meta: {
      title: 'قائمة العقارات',
      showFooter: true,
      secondHeader: true
    }
  },
  {
    path: '/agents/:id',
    name: 'agents',
    component: () => import('@/views/home/agents.vue'),
    meta: {
      title: 'قائمة المكاتب العقارات',
      showFooter: true,
      secondHeader: true
    }
  },
  {
    path: '/property/:id/:images360',
    name: 'property',
    component: () => import('@/views/home/Property.vue'),
    meta: {
      title: 'عرض العقار',
      showFooter: true,
      secondHeader: true
    }
  },
  {
    path: '/agent/:id',
    name: 'agent-properties',
    component: () => import('@/views/property/agentDetails.vue'),
    meta: {
      title: 'عرض عقارات المكتب',
      showFooter: true,
      secondHeader: true
    }
  }
]
