'use strict'

export const authRoutes = [
  {
    path: '/auth/login',
    name: 'login',
    meta: { title: 'تسجيل الدخول', showFooter: true },
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/auth/Login.vue')
  },
  {
    path: '/auth/forget-password',
    name: 'forgetpassword',
    meta: { title: 'نسيت كلمة المرور؟', showFooter: true },
    component: () => import('@/views/auth/ForgetPassword.vue')
  }
]
