'use strict'
import { extend } from 'vee-validate'

extend('validatePhone', {
  validate: (value, [isPhoneValid]) => {
    return isPhoneValid
  }
})

extend('isTermsChecked', {
  validate: (value) => {
    return value && value === true
  }
})

extend('password_regex', {
  validate: (value) => {
    var strongRegex = new RegExp('^[a-zA-Z0-9!@#$%^&*]*$')
    return strongRegex.test(value)
  }
})

extend('username_regex', {
  validate: (value) => {
    var strongRegex = new RegExp("^[a-zA-Z0-9-_']+$")
    return strongRegex.test(value)
  }
})

extend('text_regex', {
  validate: (value) => {
    var strongRegex = new RegExp('^\\S+(?: \\S+)*$')
    return strongRegex.test(value)
  }
})
