<template>
  <header
    id="header-container"
    :class="{
      'header-2': this.$route.meta.secondHeader || scrollPosition > 16
    }"
  >
    <!-- Header -->
    <div class="header-wrapper">
      <!-- <div id="header" :class="{sticky: scrollPosition > (88 + 138) && this.$route.name == 'property'}"> -->
      <div id="header">
        <!-- <div id="header"> -->
        <div class="container">
          <div class="header-inner">
            <div
              v-if="$route.path !== '/'"
              id="q-search-alt"
              class="form-search header-2-search"
            >
              <div class="q-search-inner">
                <i class="hi-magnifier"></i>
                <gmap-autocomplete
                  id="autosuggest__input-1"
                  class="autosuggest__input"
                  :component-restrictions="{ country: 'sa' }"
                  placeholder="ابحث باسم الحي أو المدينة"
                  @place_changed="setPlace"
                >
                </gmap-autocomplete>
              </div>
            </div>
            <div id="logo">
              <!-- <span class="beta-text">Beta</span> -->
              <router-link :to="{ name: 'home' }"
                ><img
                  src="../../assets/images/logo.svg"
                  alt="Site Logo"
                />
              </router-link>
            </div>
            <!-- Left Side Content -->
            <div class="left-side">
              <!-- Main Navigation -->
              <nav id="navigation" class="style-1">
                <b-nav id="responsive">
                  <li>
                    <router-link
                      :to="{ name: 'home' }"
                      exact
                      class="left-side-item"
                      >الرئيسية
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="left-side-item"
                      :to="{
                        name: 'properties',
                        params: { id: 'default' },
                        query: {
                          status: getConfigIdByName('realEstateStatus', 'rent')
                        }
                      }"
                      >للإيجار
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="left-side-item"
                      :to="{
                        name: 'properties',
                        params: { id: 'default' },
                        query: {
                          status: getConfigIdByName('realEstateStatus', 'sale')
                        }
                      }"
                      >للبيع
                    </router-link>
                  </li>
                  <li>
                    <a
                      href="#."
                      class="left-side-item"
                      v-b-modal.contracts__modal
                      >الخدمات
                      <!-- <b-button >Launch demo modal</b-button> -->
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://hectarplus.io/"
                      target="_blank"
                      class="left-side-item">
                      إداره الأملاك
                    </a>
                  </li>
                  <!-- <li>
                    <router-link
                      class="left-side-item"
                      :to="{ name: 'contact' }"
                      >تواصل معنا
                    </router-link>
                  </li> -->
                </b-nav>
              </nav>
              <div class="clearfix"></div>
              <!-- Main Navigation / End -->
            </div>
            <!-- Left Side Content / End -->
            <!-- Right Side Content / End -->
            <div class="right-side">
              <template v-if="loggedIn">
                <router-link class="mx-3 message-icon-link" to="/messaging">
                  <img :src="messagesIconHeader" class="message-icon-header" alt="Meesage Icon"/>
                </router-link>

                <!-- User Menu -->
                <b-dropdown
                  size="lg"
                  ref="userMenu"
                  variant="link"
                  class="user-menu ml-1"
                  id="user-menu"
                  toggle-class="text-decoration-none"
                >
                  <template v-slot:button-content>
                    <div class="icon-wrap">
                      <span class="hi-user icon"></span>
                      <!-- <span class="text">مرحبا بك، {{firstName}}</span> -->
                    </div>
                  </template>
                  <b-dropdown-text>
                    <span>
                      <!-- <span class="hi-hand icon ml-2"></span> -->
                      <span class="text">مرحبا {{ firstName }}</span>
                    </span>
                  </b-dropdown-text>
                  <b-dropdown-item :to="{ name: 'profile' }"
                    ><i class="hi-user"></i> الملف الشخصي
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'properties', params: { id: 'my-favorites' } }"
                    ><i class="hi-bookmark"></i> مفضلتي
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'properties', params: { id: user._id } }"
                    ><i class="hi-apartment"></i> عقارتي
                  </b-dropdown-item>
                  <!-- <b-dropdown-item :to="{name: 'my-requests'}"><i class="hi-paper-plane"></i> طلباتي</b-dropdown-item> -->
                  <b-dropdown-item to="/change-password"
                    ><i class="hi-lock"></i> تغيير كلمة المرور
                  </b-dropdown-item>
                  <b-dropdown-item exact @click.prevent="loggedOut"
                    ><i class="hi-power-switch"></i>
                    تسجيل الخروج
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Search Menu -->
                <b-dropdown
                  ref="searchMenu"
                  size="lg"
                  variant="link"
                  class="notifications-menu ml-3 hidden"
                  id="search-menu"
                >
                  <template v-slot:button-content>
                    <div class="icon-wrap">
                      <span class="hi-magnifier icon"></span>
                    </div>
                  </template>

                  <div id="q-search-alt" class="form-search">
                    <div class="q-search-inner">
                      <i class="hi-magnifier"></i>
                      <gmap-autocomplete
                        id="autosuggest__input-2"
                        class="autosuggest__input"
                        :component-restrictions="{ country: 'sa' }"
                        placeholder="ابحث باسم الحي أو المدينة"
                        @place_changed="setPlace"
                      >
                      </gmap-autocomplete>
                    </div>
                  </div>
                </b-dropdown>

                <!-- Notification Menu -->
                <b-dropdown
                  ref="notiDropdown"
                  size="lg"
                  variant="link"
                  class="notifications-menu ml-3"
                  id="notifications-menu"
                  toggle-class="text-decoration-none"
                >
                  <template v-slot:button-content>
                    <div class="icon-wrap">
                      <span class="hi-alarm icon"></span>
                      <span class="text"></span>
                      <b-badge
                        v-if="hasNewNotifications && notifications.length"
                        variant="danger"
                        >{{ notifications.length }}
                      </b-badge>
                    </div>
                  </template>
                  <b-dropdown-text id="notification-title">
                    <span>
                      <span class="hi-alarm icon ml-2"></span>
                      <span class="text">الإشعارات</span>
                    </span>
                    <span
                      ><span class="hi-close icon" @click="closeDropdown"></span
                    ></span>
                  </b-dropdown-text>

                  <template v-if="hasNewNotifications">
                    <div id="notifications-list">
                      <b-dropdown-text
                        class="notification-item"
                        v-for="item in notifications"
                        :key="item._id"
                      >
                        <span
                          ><i class="hi-angle-left"></i>
                          <span class="text">{{ item.title }}</span></span
                        >

                        <div class="notifiaction-actions">
                          <span
                            @click="
                              setNotificationState({
                                _id: item._id,
                                delete: true
                              })
                            "
                            class="review-btn remvoe-btn"
                          >
                            <span class="hi-trash"></span>
                          </span>

                          <router-link
                            :to="{
                              name: 'property',
                              params: { id: item.realEstate, images360: 0 }
                            }"
                            class="review-btn"
                          >
                            <span>معاينة العقار</span>
                            <span class="hi-arrow-left arrow-icon"></span>
                          </router-link>
                        </div>
                      </b-dropdown-text>
                    </div>
                    <!-- <b-dropdown-text class="actions-bar">
                                            <b-button @click="setNotificationsState" block variant="primary" class="button primary">
                                                <span>تحديد الكل كمقروء</span>
                                                <b-spinner v-show="isButnLoading" small class="mr-2"></b-spinner>
                                            </b-button>
                                        </b-dropdown-text> -->
                  </template>
                  <div v-else class="has-no-noti">
                    <span class="hi-alarm icon"></span>
                    <h4>ستتلقى اشعارات العقارات هنا</h4>
                    <span class="text">لا توجد اشعارات في الوقت الحالي</span>
                  </div>

                  <div class="fullwidth-loading-wrap" v-if="isButnLoading">
                    <b-spinner label="Spinning" variant="primary"></b-spinner>
                    <div class="loading-text">
                      <span>جاري حذف التنبيه...</span>
                    </div>
                  </div>
                </b-dropdown>

                <!-- Mobile Menu -->
                <b-dropdown
                  ref="mobileMenu"
                  size="lg"
                  variant="link"
                  class="notifications-menu ml-3 hidden"
                  id="mobile-menu"
                >
                  <template v-slot:button-content>
                    <div class="icon-wrap">
                      <span class="hi-line-nav icon"></span>
                      <span class="text"></span>
                    </div>
                  </template>

                  <b-dropdown-text>
                    <span>
                      <span class="hi-hand icon ml-2"></span>
                      <span class="text" v-if="loggedIn"
                        >مرحبا {{ firstName }}</span
                      >
                    </span>
                    <span
                      ><span
                        class="hi-close icon"
                        @click="closeMobileMenu"
                      ></span
                    ></span>
                  </b-dropdown-text>

                  <template v-if="loggedIn">
                    <b-dropdown-item :to="{ name: 'profile' }"
                      ><i class="hi-user"></i> الملف الشخصي
                    </b-dropdown-item>
                    <b-dropdown-item href="https://hectarplus.io/" target="_blank">
                        <i class="hi-clipboard-text"></i>
                        <span>إدارة الأملاك</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{
                        name: 'properties',
                        params: { id: 'my-favorites' }
                      }"
                      ><i class="hi-bookmark"></i> مفضلتي
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{ name: 'properties', params: { id: user._id } }"
                      ><i class="hi-apartment"></i> عقارتي
                    </b-dropdown-item>
                    <!-- <b-dropdown-item :to="{name: 'my-requests'}"><i class="hi-paper-plane"></i> طلباتي</b-dropdown-item> -->
                    <b-dropdown-item to="/change-password"
                      ><i class="hi-lock"></i> تغيير كلمة المرور
                    </b-dropdown-item>
                    <b-dropdown-item exact @click.prevent="loggedOut"
                      ><i class="hi-power-switch"></i> تسجيل الخروج
                    </b-dropdown-item>
                  </template>

                  <b-dropdown-text class="add-btn-wrap">
                    <b-button
                      variant="primary"
                      @click="addNewProperty"
                      class="button primary m-0 mb-5"
                    >
                      <span class="hi-plus2 ml-2"></span>
                      <span class="text">اضف عقار</span>
                    </b-button>
                  </b-dropdown-text>
                </b-dropdown>
              </template>

              <router-link
                v-else
                to="/auth/login"
                class="sign-in ml-3 font-weight-bold"
              >
                تسجيل دخول/ اشتراك
              </router-link>

              <b-button
                variant="primary"
                @click="addNewProperty"
                class="button primary background header-add-property-btn m-0"
              >
                <span class="hi-plus2 icon hidden"></span>
                <span class="text">اضف عقار</span>
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <b-modal
            id="contracts__modal"
            centered
            title="الخدمات"
            hide-footer
            size="lg"
          >
            <h6 class="mb-4 contract__top--title">
              توثيق عقود الإيجار من خلال منصة إيجار
            </h6>
            <div class="d-flex justify-content-center contracts__div flex-wrap">
              <div
                class="contracts contract__commercial"
                @click="$bvModal.hide('contracts__modal')"
              >
                <router-link
                  class="mx-3 message-icon-link"
                  :to="{ path: '/rent-contract', query: { price: 395 } }"
                >
                  <div class="contract__commercial--box">
                    <div class="contract__box--icon">
                      <img
                        src="../../assets/images/contracts-svg.svg"
                        alt="Contracts Image"
                      />
                    </div>
                    <!-- contract__box--icon -->
                    <div class="contract__box--text">
                      <p>رسوم إجراء العقد التجاري</p>
                    </div>
                    <!-- contract__box--text -->
                    <div class="contract__box--price">
                      <p class="fw-500">395 ريال</p>
                    </div>
                    <!-- contract__box--price -->
                  </div>
                  <!-- contract__commercial--box -->
                </router-link>
              </div>
              <!-- contract__commercial -->
              <div
                class="contracts contract__residential"
                @click="$bvModal.hide('contracts__modal')"
              >
                <router-link
                  class="mx-3 message-icon-link"
                  :to="{ path: '/rent-contract', query: { price: 275 } }"
                >
                  <div class="contract__residential--box">
                    <div class="contract__box--icon">
                      <img
                        src="../../assets/images/contracts-svg.svg"
                        alt="Contracts Image"
                      />
                    </div>
                    <!-- contract__box--icon -->
                    <div class="contract__box--text">
                      <p>رسوم إجراء العقد السكني</p>
                    </div>
                    <!-- contract__box--text -->
                    <div class="contract__box--price">
                      <p class="fw-500">275 ريال</p>
                    </div>
                    <!-- contract__box--price -->
                  </div>
                  <!-- contract__residential--box -->
                </router-link>
              </div>
              <!-- contract__residential -->
            </div>
          </b-modal>
        </div>
        <!-- container  -->
      </div>
    </div>
    <div class="clearfix"></div>
    <!-- Header / End -->

    <!-- Mobile Notification to download apps -->
    <DownloadAppsModal></DownloadAppsModal>
  </header>
</template>
<style scoped>
#notifications-list {
  overflow-y: auto;
  overflow-x: hidden;
  width: 98%;
}

#notification-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 5px;
}
</style>

<script>
import { EventBus } from '@/services/EventBus'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { realEstateService } from '../../services'
import DownloadAppsModal from '@/components/modals/DownloadAppsModal.vue'

import config from '@/config'

export default {
  name: 'Header',
  components: {
    DownloadAppsModal
  },
  data() {
    return {
      scrollPosition: null,
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      places: [],
      currentPlace: null,
      value: '',
      notifications: [],
      hasNewNotifications: [],
      isButnLoading: false,
      checked: false,
      messagesIconHeader: require('../../assets/images/svgs/messages-icon-header.svg')
    }
  },
  watch: {
    loggedIn() {
      // if(val == false){
      //     alert('logged out now');
      // }
    },
    $route: {
      immediate: false,
      handler() {
        let isLoggedInFromStorage = localStorage.getItem('logged_in')
        if (isLoggedInFromStorage === 'false') {
          this.setLoggedInStatus(false)
          this.hasNewNotifications = []
          this.notifications = []
        } else if (isLoggedInFromStorage === 'true') {
          this.setLoggedInStatus(true)

          // Get Notifications
          this.getNotifications()
        }
      }
    }
  },
  mounted() {
    // LocalStorage Event fires if you logged out from one tab, will logout from all others opend tabs
    this.watchStorage()

    // add Class When Input Search Has Vaule
    this.addClassInputSearch()

    this.showDownloadAppsModal()
  },
  computed: {
    ...mapState('realEstateState', ['isDisplayedOnce', 'propertiesFound']),
    ...mapState('agentLocator', ['agentLocator']),
    ...mapState('accountState', ['user', 'status', 'loggedIn', 'favoritesIds']),
    ...mapGetters('accountState', ['getConfigIdByName']),
    firstName() {
      let name = this.user?.name?.split(' ')
      if (name?.length == 1) {
        return name[0] //.replace(/ .*/, '');
      }
      if (name?.length == 2) {
        return name[0] + ' ' + name[1]
      }
      if (name?.length >= 3) {
        return name[0] + ' ' + name[1] + ' ' + name[2]
      }
      return null
    },
    currentRouteName() {
      return this.$route.name
    },
    dumyNotifications() {
      return config.notifications
    },
    mq() {
      return this.$mq
    }
  },
  methods: {
    ...mapActions('accountState', ['logout']),
    ...mapMutations('accountState', ['setLoggedInStatus']),
    ...mapMutations('realEstateState', [
      'setIsDisplayedOnce',
      'setMapCenter',
      'setMapEventsFlag'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    updateScroll() {
      this.scrollPosition = window.scrollY

      if (this.scrollPosition == 0) {
        document.querySelector('.pac-container').style.display = 'none'
      }
    },

    // receives a place object via the autocomplete component
    setPlace(place) {
      this.setMapEventsFlag(false)

      // check if correct returned place
      if (Object.prototype.hasOwnProperty.call(place, 'geometry')) {
        this.currentPlace = place
        this.$emit('placeChanged', place)

        const currentPlaceLocation = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        }

        // store.commit('realEstateState/setMapCenter', currentPlaceLocation);
        this.setMapCenter(currentPlaceLocation)

        EventBus.$emit('placeSelected', place)

        // if at any page other listings map, go to it after set place
        if (
          this.currentRouteName != 'home' &&
          this.$route.params.id != 'default'
        ) {
          this.$router.push({
            name: 'properties',
            params: { ...currentPlaceLocation, id: 'default' }
          })
        }
      }
    },

    loggedOut() {
      this.logout()
    },

    addNewProperty() {
      var isLoggedInFromStorage = localStorage.getItem('logged_in')
      if (isLoggedInFromStorage !== 'true') {
        this.$toasted.show('من فضلك قم بتسجيل الدخول أولاً', {
          duration: 4000
        })
        if (this.$route.name !== 'login') this.$router.push({ name: 'login' })
      } else if (this.user?.type === 'seeker')
        this.$toasted.show(
          'نوع المستخدم باحث عن عقار لا يمكنه إضافة عقارات، فضلاً قم بتغيبر نوع الحساب من إعدادات ملفك الشخصي',
          {
            duration: 5000,
            icon: 'hi-close',
            position: 'top-center',
            type: 'error',
            action: [
              {
                text: 'من هنا',
                onClick: (e, toastObject) => {
                  this.$router.push('/profile')
                  toastObject.goAway(0)
                }
              }
            ]
          }
        )
      else {
        this.$router.push({ name: 'addProperty', params: { id: 'new' } })
      }

      this.closeMobileMenu()
    },

    openNotiDropdown() {
      this.$refs.notiDropdown.show()
    },
    closeDropdown() {
      this.$refs.notiDropdown.hide(true)
    },
    closeMobileMenu() {
      this.$refs.mobileMenu.hide(true)
    },
    setNotificationState(options) {
      this.isButnLoading = true
      realEstateService.setNotificationState(options).then(
        res => {
          this.isButnLoading = false
          if (options.delete == true && res.data.success) {
            // delete the item from the array
            let deletedIndex = -1
            for (var i = 0; i < this.notifications.length; i += 1) {
              if (this.notifications[i]['_id'] === options._id) {
                deletedIndex = i
              }
            }
            this.notifications.splice(deletedIndex, 1)
          }
        },
        error => {
          this.$toasted.show(error, { duration: 4000 })
        }
      )
    },

    showDownloadAppsModal() {
      if ((this.mq === 'sm' || this.mq === 'xs') && !this.isDisplayedOnce) {
        this.$bvModal.show('download-apps-modal')
        this.setIsDisplayedOnce(true)
      }
    },
    watchStorage() {
      window.addEventListener(
        'storage',
        function(e) {
          if (e.key === 'logged_in' && e.newValue === 'false') {
            location.reload()
          }
        },
        false
      )
    },
    getNotifications() {
      if (this.loggedIn) {
        // Get User Notifications
        realEstateService.getUserNotifications().then(
          res => {
            this.notifications = res.data.notification
          },
          error => {
            console.log(error)
          }
        )
      }
    },
    addClassInputSearch() {
      window.addEventListener('scroll', this.updateScroll)

      // add class when input search has value
      const $body = document.querySelector('body')
      const $searchInput = document.querySelectorAll('.autosuggest__input')
      const typeHandler = function(e) {
        if (e.target.value) {
          $body.classList.add('search-is-active')
        } else {
          $body.classList.remove('search-is-active')
        }
      }
      $searchInput.forEach(function(elem) {
        elem.addEventListener('input', typeHandler)
      })
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  }
}
</script>

<style lang="scss" scoped>
.toasted-container .toasted .action {
  padding: 0px 15px !important;
  color: white !important;
  font-size: 15px;
  font-weight: 900;
}
.contracts__div {
  gap: 20px;
  padding-bottom: 3rem;
  .contracts {
    border: 2px solid #f7f5f5;
    border-radius: 10px;
    min-width: 25%;
    text-align: center;
    padding: 1.5rem 4rem;
    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      .contract__box--price p {
        color: #9f9fac !important;
      }
    }
  }
  .contract__box--icon {
    img {
      width: 32px;
      margin: 10px 0;
    }
  }
  .contract__box--text p {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #101010 !important;
  }
  .contract__box--price p {
    font-size: 15px;
    font-weight: 500;
    color: #737373 !important;
  }
}
// contracts__div

::v-deep {
  .modal-content {
    border-radius: 8px;
    border: 0;
    .modal-header {
      border-color: #f7f5f5;
      padding-bottom: 0.7rem;
      .modal-title {
        font-weight: 700;
        color: #000;
      }
      .close {
        &:after {
          font-size: 14px;
        }
      }
    }
    .modal-body {
      p {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        margin-bottom: 0;
      }
    }
  }
}
</style>
